import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Button, SubTitle } from "../utils"

const TagsWrap = styled.div`
  display: flex;
`

const Tags = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulTags {
          edges {
            node {
              name
              id
              tagSlug
            }
          }
        }
      }
    `
  )
  const tags = data.allContentfulTags.edges
  return (
    <div>
      <SubTitle>Tags</SubTitle>
      <TagsWrap>
        <Button>
          <Link to="/recipe">All</Link>
        </Button>
        {tags &&
          tags.map(({ node: tag }) => {
            return (
              <Button>
                <Link to={`/tag/${tag.tagSlug}`}>{tag.name}</Link>
              </Button>
            )
          })}
      </TagsWrap>
    </div>
  )
}

export default Tags
