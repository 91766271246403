import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Button, FlexWrap, SubTitle } from "../utils"

const Categories = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulCategory {
          edges {
            node {
              name
              slug
              id
            }
          }
        }
      }
    `
  )
  const categories = data.allContentfulCategory.edges
  return (
    <div>
      <SubTitle>Categories</SubTitle>
      <FlexWrap>
        <Button>
          <Link to="/recipe">All</Link>
        </Button>
        {categories &&
          categories.map(({ node: cate }) => {
            return (
              <Button>
                <Link to={`/category/${cate.slug}`}>{cate.name}</Link>
              </Button>
            )
          })}
      </FlexWrap>
    </div>
  )
}

export default Categories
