import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import {
  Article,
  Column3,
  ContainerCol2,
  Section,
  Sidebar,
  Thumbnail,
  Title,
} from "../utils"
import Tags from "../components/tags"
import Categories from "../components/categories"

const Text = styled.div``

const CreatedAt = styled.p``

const RecipePage = ({ data }) => {
  const recipes = data.allContentfulRecipes.edges
  return (
    <ContainerCol2>
      <Section>
        <Column3>
          {recipes &&
            recipes.map(({ node: recipe }) => {
              return (
                <Article>
                  <Link to={`/recipe/${recipe.slug}/`}>
                    <Thumbnail>
                      {recipe.thumbnail && ( //もしサムネイル画像をもっていれば
                        <GatsbyImage image={getImage(recipe.thumbnail)} />
                      )}
                    </Thumbnail>
                    <Text>
                      <Title>{recipe.title}</Title>
                      <CreatedAt>{recipe.createdAt}</CreatedAt>
                    </Text>
                  </Link>
                </Article>
              )
            })}
        </Column3>
      </Section>
      <Sidebar>
        <Tags />
        <Categories />
      </Sidebar>
    </ContainerCol2>
  )
}

export default RecipePage

export const query = graphql`
  query {
    allContentfulRecipes: allContentfulRecipes(
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          thumbnail {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          createdAt(formatString: "YYYY-MM-DD")
        }
      }
    }
  }
`
